<template>
  <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
    <div class="see-img-box flexRow1 justifyContentCenter alignItems">
      <img width="70%" :src="url" alt=""></div>
  </el-dialog>
</template>

<script>
export default {
  name: "picture-dialog",
  props: {
    url: String,
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.dialogVisible = val;
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    handleClose() {
      let _this = this;
      _this.dialogVisible = false;
      _this.$emit('update:visible', false);
    },
  }
}
</script>

<style scoped>

</style>
