<template>
  <div>
    <template v-for="(item, index) in menuData" v-if="!item.hidden">
      <el-submenu :index="index" :key="index" @open="addMenu(item, index)" v-if="item.folder">
        <template slot="title">
          <i :class="item.icon" v-if="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </template>
        <menu-tree :menuData="item.children"></menu-tree>
      </el-submenu>
      <el-menu-item v-else :key="item.id" :index="item.url">
        <i :class="item.icon" v-if="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
    <!-- <template v-for="(item, index) in menuData" v-if="!item.hidden">
      <el-submenu :index="item.path" :key="item.path" v-if="item.children && item.children.length>1">
        <template slot="title">
          <span slot="title">{{ item.meta.title }}</span>
        </template>
        <menu-tree :menuData="item.children"></menu-tree>
      </el-submenu>
      <el-menu-item v-else :key="item.path" :index="item.path">
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </template> -->
  </div>
</template>
<script>
// import MenuTree from './item'
export default {
  props: ['menuData'],
  name: 'MenuTree',
  data() {
    return {}
  },
  methods: {
    addMenu(item, index) {
      let _that = this
      if (item.folder) {
        _that.menuData[index].children = []
        let data = {
          id: item.id,
        }
        _that.$api.GetmenuList(data).then((res) => {
          if (res.data.code == 200) {
            _that.menuData[index].children.push(res.data.data)
          } else {
            _that.utils.error(res.data.message)
          }
        })
      }
    },
  },
}
</script>
