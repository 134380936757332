import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './plugins/element.js'
import utils from './utils/utils'
// import './styles/index.scss' // global css
import api from './api/article' // 导入api接口
import baseUrl from './api/api' // 导入api接口
import md5 from 'js-md5'; // md5加密
import 'lib-flexible';
import './assets/css/common.less' // 导入common
// 省市区街道
import { pcaa } from 'area-data-vue';
import 'area-linkage-vue/dist/index.css';
import AreaLinkageVue from 'area-linkage-vue';
Vue.prototype.$pcaa = pcaa;

Vue.use(AreaLinkageVue);


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

/*左边工具栏以及编辑节点的样式*/
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
/*右边工具栏样式*/
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
/* 公用样式 */
import './assets/css/reset.css';
import './assets/css/all.less';
import './assets/icon/iconfont.css';
import './assets/css/css.css';

/* 组件 */
import plugins from './components/index'
/* 全局注册组件 */
Vue.use(plugins)


Vue.prototype.$api = api; // 将api挂载到vue的原型上
Vue.prototype.$md5 = md5;

Vue.prototype.baseUrl = baseUrl.url;
// 正则
import Reg from './utils/reg'
Vue.prototype.$Reg = Reg


// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.prototype.utils = utils; //全局方法
Vue.prototype.store = store; //vuex

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
