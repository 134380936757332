import ElementUI from "element-ui";

let OSS = require("ali-oss");
const utils = {
    // 表头样式
    getRowClass() {
        return {
            background: "#ECF3FC",
            color: "black",
            // fontSize: "14px",
        };
    },
    // 成功提示
    success(text) {
        ElementUI.Message({
            message: text,
            type: "success",
        });
    },
    // 错误提示
    error(text) {
        ElementUI.Message({
            message: text,
            type: "error",
        });
    },
    // 手机格式
    isPhone(str) {
        return /^1\d{10}$/.test(str);
    },
    // 邮箱格式
    isEmail(str) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
            str,
        );
    },
    // 防抖
    debounce(func, delay) {
        let timer = null;
        return function () {
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, arguments);
            }, delay);
        };
    },

    // 节流
    throttle(func, wait) {
        let start = 0;
        return function () {
            let now = Date.now();
            if (now - start >= wait) {
                func.apply(this, arguments);
                start = now;
            }
        };
    },
    upFile(file) {
        return new Promise((resolve, reject) => {
            let client = OSS({
                policy: "eyJleHBpcmF0aW9uIjoiMjAyMC0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==",
                accessKeyId: "LTAI5tNLi3EggkXHFcuSML5W",
                accessKeySecret: "76O2SxndR8rS02zUzi9WG1bu0hPafg",
                bucket: "swshopimg",
                secure: true,
                signature: "scFcHwrpUYnZDvDYgeOOqFJGTTE=",
                region: "oss-cn-zhangjiakou",
            });
            client.options.endpoint.href = "https://swshopimg.oss-cn-zhangjiakou.aliyuncs.com";
            client.options.endpoint.protocol = "https:";
            let ossUpAddress = "mini/";
            let tiemr = new Date();
            let address = tiemr.getFullYear() + "" + (tiemr.getMonth() + 1) + "" + tiemr.getDate(); //获取当前日期
            address = ossUpAddress + address + "/"; //文件放在当前日期下
            let str = file.name.substr(file.name.lastIndexOf(".")); //截取文件后缀名
            let nameStr = address + tiemr.getTime() + str; //拼接地址
            let ret = client
                .put(nameStr, file, {
                    progress: async function (p) {
                        let e = {};
                        e.percent = p * 100;
                        option.onProgress(e);
                    },
                })
                .then((res) => {
                    let datas = res;
                    datas.fileName = file.name
                    datas.size = file.size
                    datas.uid = file.uid
                    datas.time = new Date().getTime()
                    resolve(datas)
                });
        });
    },
};
export default utils;
