<template>
  <el-container>
    <el-header class="header-nav">
      <div class="left-title">
        <!--        <img src="../assets/img/logo.png"/>-->
        <span>首 望</span>
      </div>
      <div class="right-menus">
        <!--        <div class="store-name" @click="hanldeDownClick($event)">-->
        <!--          <span>{{ storeName }}</span>-->
        <!--          <i id="store_list" class="el-icon-arrow-down"></i>-->
        <!--        </div>-->
        <span v-if="storeList.length==1">{{ storeName }}</span>
        <ul class="right-menus-ul">
          <li v-if="storeList.length>1">
            <el-select
                id="store_list1"
                v-model="storeId"
                class="index-selects"
                placeholder="专店选择"
                @change="changeStoreId"
            >
              <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </li>
          <li @click="handleUserInfo">
            <img class="tu" style="vertical-align: middle;" v-if="avatar !== null " :src="avatar"/>
            <img class="tu" style="vertical-align: middle;" v-else src="../assets/img/avatar.png"/>
            <span style="vertical-align: middle;">{{ username }}</span>
          </li>
          <li @click="handleCommand">退出登录</li>
        </ul>
        <div class="avatar-nitice" @click="handleNews">
          <i class="el-icon-bell"></i>
          <!--          <span>1</span>-->
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside>
        <el-scrollbar style="height: 90%;padding: 0;">
          <el-tree
              :props="defaultProps"
              highlight-current
              accordion
              ref="asyncTree"
              node-key="id"
              :load="loadNode"
              :current-node-key="idArr"
              :default-expanded-keys="expandedList"
              lazy
              class="tree_css"
              style="background-color: #FCFCFC;"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }" @click="goLink(data, node)">
              <span style="display: flex;align-items: center;">
                <img
                    class="imgIcon"
                    :class="data.icon"
                    v-if="data.icon.indexOf('Icon') > 0"
                    :src="require('../assets/img/icon/' + data.icon + '.png')"
                    alt
                />
                <i v-else class="iconfont" style="margin-right: 10px; font-size: 20px; color: #fff"></i>
                <span class="font_color">{{ data.name }}</span>
              </span>
              <!--<i class="el-icon-arrow-right" v-if="data.folder"></i>-->
            </span>
          </el-tree>
        </el-scrollbar>
      </el-aside>
      <el-main>
        <!-- <div class="header-logo">
          <img src="../assets/img/logo.png" />
          <span>首 望</span>
        </div> -->
        <transition name="fade-transform" mode="out-in">
          <router-view class="router-view"></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
  <!--  <el-container>-->
  <!--    <div class="left_contant">-->
  <!--      <div class="left-title">-->
  <!--        <img v-if="avatar !== 'null' " :src="avatar"/>-->
  <!--        <img v-else src="../assets/img/avatar.png"/>-->
  <!--        <span>{{ username }}</span>-->
  <!--      </div>-->
  <!--      <el-scrollbar style="height:88%;">-->
  <!--        <el-tree-->
  <!--            :props="defaultProps"-->
  <!--            highlight-current-->
  <!--            accordion-->
  <!--            ref="asyncTree"-->
  <!--            node-key="id"-->
  <!--            :load="loadNode"-->
  <!--            :current-node-key="idArr"-->
  <!--            :default-expanded-keys="expandedList"-->
  <!--            lazy-->
  <!--            class="tree_css"-->
  <!--            style="background-color: #FCFCFC"-->
  <!--        >-->
  <!--          <span class="custom-tree-node" slot-scope="{ node, data }" @click="goLink(data, node)">-->
  <!--            <span style="display: flex;align-items: center;">-->
  <!--              <img-->
  <!--                  class="imgIcon"-->
  <!--                  :class="data.icon"-->
  <!--                  v-if="data.icon.indexOf('Icon') > 0"-->
  <!--                  :src="require('../assets/img/icon/' + data.icon + '.png')"-->
  <!--                  alt=""-->
  <!--              />-->
  <!--              <i v-else class="iconfont" style="margin-right: 10px; font-size: 20px; color: #fff"></i>-->
  <!--              <span class="font_color">{{ data.name }}</span>-->
  <!--            </span>-->
  <!--            <i class="el-icon-arrow-right" v-if="data.folder"></i>-->
  <!--          </span>-->
  <!--        </el-tree>-->
  <!--      </el-scrollbar>-->
  <!--    </div>-->
  <!--    <el-container>-->
  <!--      <el-header class="header-nav">-->
  <!--        <ul class="right-menus">-->
  <!--          <li>-->
  <!--            <el-select v-model="storeId" class="index-selects" placeholder="专店选择" @change="changeStoreId">-->
  <!--              <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
  <!--            </el-select>-->
  <!--          </li>-->
  <!--          <li @click="handleUserInfo">账户设置</li>-->
  <!--          <li @click="handleCommand">退出登录</li>-->
  <!--        </ul>-->
  <!--        <div class="avatar-nitice" @click="handleNews">-->
  <!--          <i class="el-icon-bell"></i>-->
  <!--          <span>1</span>-->
  <!--        </div>-->
  <!--      </el-header>-->
  <!--      <el-main>-->
  <!--        <div class="header-logo">-->
  <!--          <img src="../assets/img/logo.png"/>-->
  <!--          <span>首 望</span>-->
  <!--        </div>-->
  <!--        <transition name="fade-transform" mode="out-in">-->
  <!--          <router-view class="router-view"></router-view>-->
  <!--        </transition>-->
  <!--      </el-main>-->
  <!--    </el-container>-->
  <!--  </el-container>-->
</template>
<script>
// import screenfull from 'screenfull'
import MenuTree from "./item";
import {routes} from "@/router";

export default {
  components: {
    MenuTree
  },
  data() {
    return {
      username: "",
      avatar: null,
      activeName: "tab1",
      userFlag: false, //个人信息
      pwdFlag: false, //修改密码
      newsFlag: false, //消息弹框
      sizi: "606px",
      date: "", //消息日期选择
      userInfo: [], //用户信息

      defaultProps: {
        label: "name",
        children: "zones",
        isLeaf: "leaf"
      },
      routerList: routes,
      menuId: -1,
      idArr: "",
      expandedList: [],
      jurisdictionList: [],
      formMenu: "",
      toMenu: "",
      nodeId: "",
      storeList: [],
      storeId: sessionStorage.getItem("storeId"),
      // storeName: sessionStorage.getItem("storeName"),
      storeName: "",
    };
  },
  created() {
    if (localStorage.getItem("nodeId")) {
      //判断是否有存id
      this.expandedList.push(localStorage.getItem("nodeId")); //默认父节点此展开
    }
    this.getUserInfo();
    this.getTreeSelect();
  },
  watch: {
    $route(to, from) {
      this.toMenu = to.path;
      this.formMenu = from.path;
    }
  },
  methods: {
    hanldeDownClick(e) {
      //   const ele = document.getElementsByClassName('el-select-dropdown')
      //   // console.log(ele);
      //   ele.style.display = 'block';
      //   console.log(e);
      //   // e.currentTarget.firstElementChild.click();
    },
    changeStoreId(e) {
      sessionStorage.setItem("storeId", e);
      for (let i = 0; i < this.storeList.length; i++) {
        if (e == this.storeList[i].id) {
          this.storeName = this.storeList[i].name;
          sessionStorage.setItem("storeName", this.storeList[i].name);
        }
      }
      this.$router.go(0);
    },
    getUserInfo() {
      let that = this;
      that.$api.getloginInfo().then(res => {
        if (res && res.data.code == 200) {
          this.username = res.data.data.loginName;
          this.avatar = res.data.data.avatar;
          // sessionStorage.setItem("nickName", res.data.data.nickName);
          sessionStorage.setItem("nickName", res.data.data.loginName);
          sessionStorage.setItem("loginName", res.data.data.loginName);
          sessionStorage.setItem("nickNameId", res.data.data.id);
          sessionStorage.setItem("avatar", res.data.data.avatar);
        }
      });
    },
    //个人信息 编辑-保存
    isEditFn(falg) {
      this.isEdit = falg;
      this.userFlag = true;
    },
    // 设置tree默认高亮
    getTreeSelect() {
      if (localStorage.getItem("ids")) {
        //判断是否有存id
        this.idArr = localStorage.getItem("ids");
      }
    },
    //
    goLink(e) {
      // 获取页面按钮权限
      let _that = this;
      if (!e.folder) {
        _that.$api.GetmenuButton({
          id: e.id
        }).then((res) => {
          if (res && res.data.code === 200) {
            // console.log('权限', res.data.data)
            localStorage.setItem("jurisdictionList", JSON.stringify(res.data.data));
          }
        })
      }
      if (!e.folder && e.parent == -1) {
        localStorage.removeItem("nodeId");
        localStorage.setItem("ids", e.id); //设置菜单只要一级的时候默认高亮
      }

      if (e.parent == -1 && this.toMenu != this.$route.path) {
        //设置菜单多级的时候默认高亮
        localStorage.setItem("oldId", e.id); //存储父级id，以免刷新id丢失
      } else {
        if (this.formMenu != this.$route.path) {
          let id = localStorage.getItem("oldId");
          localStorage.setItem("ids", e.id); //默认高亮子级id
          localStorage.setItem("nodeId", id); //展开父节点id
        }
      }

      if (e.url != "" && !e.folder) {
        //路由跳转
        this.$router.push({
          path: e.url
        });
      }
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.queryDeptList(resolve);
      }
      if (node.level >= 1) {
        this.getChildByList(node.data.id, resolve); //获取子节点数据
        return resolve([]);
      }
    },
    getChildByList(id, resolve) {
      let _that = this;
      let data = {
        id: id
      };
      _that.$api.GetmenuList(data).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.map(item => {
            item.leaf = !item.folder;
          });
          resolve(data);
        } else {
          _that.utils.error(res.data.message);
        }
      });

    },
    // 获取菜单列表
    queryDeptList(resolve) {
      let _that = this;
      let data = {
        id: _that.menuId
      };
      _that.$api.GetmenuList(data).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          _that.idArr = res.data.data[0].id; //默认高亮id
          data.map(item => {
            item.leaf = !item.folder;
          });
          _that.getTreeSelect();
          resolve(data);
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
    goHome() {
      this.$router.push({
        path: "/home"
      });
    },
    routerToView(key) {
      this.$router.push({
        path: key
      });
    },
    handleOpen(key, keyPath, zindex) {
    },
    handleClose(key, keyPath) {
    },
    handleUserInfo() {
      let _that = this;
      _that.$router.push({
        path: "/userInfo"
      });
    },
    handleNews() {
      let _that = this;
      _that.$router.push({
        path: "/news"
      });
    },
    handleCommand() {
      let _that = this;
      this.$confirm("此操作将退出登录, 是否继续?", "退出确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      })
          .then(() => {
            _that.$api.logout().then(res => {
              if (res.data.code == 200) {
                _that.$router.push({
                  path: "/"
                });
                localStorage.clear();
                _that.utils.success("退出成功");
              } else {
                _that.utils.error(res.data.message);
              }
            });
          })
          .catch(() => {
          });
    }
  }
};
</script>
<style scoped lang="less">
.index-select {
  //background: #fff;
  /deep/ .el-input__inner {
    //width: 150px;
    width: auto;
    border: none;
    background: rgb(70, 76, 91) !important;
  }

  /deep/ .el-input__icon {
    height: auto;
  }
}

.store-check {
  cursor: pointer;
  background-color: rgb(70, 76, 91);
}

.ysIcon {
  width: 17px !important;
  height: 17px !important;
  margin-left: 2px;
}

.kqIcon {
  width: 15px !important;
  height: 15px !important;
  margin-left: 3px;
}

.iconfont {
  display: inline-block;
  width: 4px;
  height: 4px;
}

.imgIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headers > div:nth-of-type(1) {
  font-size: 25px;
  font-weight: bold;
  color: #172e66;
  padding-left: 10px;
  box-sizing: border-box;
}

.icon {
  margin: 0 10px;
  font-size: 20px;
}

.hei20 {
  height: 20px;
  line-height: 20px;
}

.left_contant {
  background: rgb(252, 252, 252);
}

.page,
.el-container,
.left_contant {
  height: 100%;
}

.screen {
  font-size: 25px;
  font-weight: 500;
  cursor: pointer;
}

.header {
  height: 80px;
  padding-right: 20px;
}

.userimages {
  height: 60px;
}

.userimages > img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 15px;
}

.head-username {
  display: flex;
  align-items: center;
}

.userimagesInfo {
  height: 50px;
  font-size: 16px;
  color: #172e66;
  font-weight: 900;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userimagesInfo > i {
  letter-spacing: 4px;
}

.userimagesInfo > div {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.userimagesInfo > div > img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.el-container.is-vertical {
  background: rgb(240, 242, 245);
}

.user-name {
  text-align: center;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 3px;
}

.left-title {
  //width: 100%;
  height: 61px;
  //background-image: url(../assets/img/logo-text.png);
  //background-repeat: no-repeat;
  //background-size: 80%;
  //background-position: center;
  display: flex;
  align-items: center;
  margin-left: 20px;
  background: rgb(70, 76, 91);

  img {
    width: 35px;
    height: 35px;
    margin: 0 20px;
    color: rgb();
    border-radius: 100px;
  }

  span {
    font-family: "PingFang SC ", "PingFang SC", sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgb(215, 215, 215);
    text-align: left;
  }
}

.header-logo {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  background: #fff;

  img {
    width: 35px;
    height: 35px;
    margin: 0 20px 0 40px;
  }

  span {
    font-family: "PingFang SC ", "PingFang SC", sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 24px;
    color: #0096fa;
    text-align: left;
  }
}

.head-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-left: 20px;
}

.router-view {
  /* background-color: #fff; */
  margin: 20px 0;
}

.top-icon {
  font-size: 30px;
}

/deep/ .el-header {
  background: #fff;
  padding: 0 20px 0 0;
}

.el-main {
  padding: 0;
  width: 100%;
  position: relative;
  min-width: 1340px;
  overflow-x: hidden;
}

.el-menu {
  width: 200px;
}

// /deep/ .el-tree{
//   background-color: #0F1B39;
// }
// /deep/ .el-tree-node__content {
//   //padding-right: 10px;
// }

.tree_css {
  //width: 220px;
  /deep/ .el-icon-caret-right {
    opacity: 1;
    // display: none;
    position: absolute;
    right: 6px;
    font-size: 15px;
    color: #3b3a3a;
  }

  /deep/ .el-tree-node__content .is-leaf {
    color: transparent !important;
  }

  /deep/ .el-icon-caret-right:before {
    content: "\e6e0";
  }

  .custom-tree-node {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(242, 242, 242);
    //border-bottom: 1px solid red;

    &:hover {
      //background-color: #fcfcfc;
      color: rgb(0, 194, 151);
      border-bottom: 1px solid rgb(242, 242, 242);

      .font_color {
        color: rgb(0, 194, 151);
      }

      /deep/ .el-icon-arrow-right {
        color: rgb(0, 194, 151);
      }
    }
  }

  /deep/ .el-icon-arrow-right {
    padding-right: 10px;
    color: #3b3a3a;
  }

  ///deep/ .el-icon-caret-right {
  //  opacity: 0;
  //  display: none;
  //}

  /deep/ .el-tree-node__content {
    height: 50px;
    text-align: center;
    border-left: 5px solid transparent;
  }

  .font_color {
    font-size: 16px;
    //color: #b8b5b5;
    color: #3b3a3a;
  }

  /deep/ .is-current > .el-tree-node__content {
    border-left: 5px solid #4b80ff;
    // background-color: rgba(243, 149, 23, 0.08) !important;
    // .font_color {
    //   color: #4B80FF !important;
    // }
  }

  .el-tree-node__content {
    background-color: rgba(243, 149, 23, 0.08) !important;
  }

  /deep/ .el-tree-node__content:hover {
    //background-color: rgba(243, 149, 23, 0.08) !important;
    background-color: none !important;
  }
}

/deep/ .el-tree-node__content:hover {
  //background-color: rgba(243, 149, 23, 0.08) !important;
  background-color: #fff !important;
}

// .arrowTransform {
//   transition: 0.2s;
//   transform-origin: center;
//   transform: rotateZ(0deg);
// }

// .arrowTransformReturn {
//   transition: 0.2s;
//   transform-origin: center;
//   transform: rotateZ(90deg);
// }

// /deep/
//   .el-tree--highlight-current
//   .el-tree-node.is-current
//   > .el-tree-node__content {
//   //background: #4b80ff !important;
// }

/deep/ .tree_css .custom-tree-node[data-v-3d6890b4] {
  color: #fff !important;
}

// .is-current .el-icon-arrow-right{
//   transform: rotate(90deg);
// }
.messages {
  width: 40px;
  height: 40px;
  opacity: 1;
  background: #e9ecff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages > img {
  width: 25px;
  height: 25px;
}

.drawer-title {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 0 22px;
  font-size: 18px;
  font-weight: 600;
  color: #383b40;
  border-bottom: 2px solid #e8ecef;

  .drawer-title-left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    ::v-deep {
      .el-tabs__nav-wrap::after {
        background-color: transparent;
      }

      .el-tabs--top {
        height: 40px;
      }

      .el-tabs__item {
        color: #383b40;
      }

      .el-tabs__item.is-active {
        color: #364164;
        font-weight: 600;
      }

      .el-tabs__active-bar {
        background-color: #172e66;
      }

      .el-tabs__item:focus.is-active.is-focus:not(:active) {
        box-shadow: none;
      }
    }
  }

  .el-icon-close {
    font-size: 20px;
    color: #778ca2;
    cursor: pointer;
  }
}

.drawer-info-bg {
  background: #f5f6f8;
  box-shadow: -3px 2px 17px 0px rgba(161, 161, 161, 0.5);
}

.drawer-info {
  height: calc(100vh - 70px);
  box-sizing: border-box;
  overflow-y: auto;
  min-height: 600px;
  padding: 0 20px;

  .select-time {
    padding: 12px 0;
  }

  .list {
    .list-li {
      cursor: pointer;
      margin-top: 8px;
      background: #ffffff;
      border-radius: 10px;
      height: 84px;
      display: flex;
      align-items: center;
      padding: 20px 22px;

      &:first-child {
        margin-top: 0;
      }

      .li-icon {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        border-radius: 8px;

        .el-icon-chat-dot-square {
          font-size: 24px;
        }
      }

      .active1 {
        background-color: #e9ecff;

        .el-icon-chat-dot-square {
          color: #2f4cdd;
        }
      }

      .active2 {
        background-color: #f1f1f1;

        .el-icon-chat-dot-square {
          color: #3e4954;
        }
      }

      .active3 {
        background-color: #ffebe7;
        //background-color: red;

        .el-icon-chat-dot-square {
          color: #ff6d4d;
        }
      }

      .active4 {
        background-color: #f4fcf6;

        .el-icon-chat-dot-square {
          color: #2bc155;
        }
      }

      .li-cont {
        flex: 1;

        .li-cont-top {
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tip {
            font-size: 16px;
            color: #383b40;
            font-weight: 600;
            max-width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .time {
            font-size: 12px;
            color: #778ca2;

            span {
              margin-left: 4px;
            }
          }
        }

        .li-cont-bot {
          margin-top: 2px;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .info {
            font-size: 14px;
            color: #778ca2;
            max-width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .name {
            font-size: 12px;
            font-weight: 400;
            color: #778ca2;

            span {
              display: inline-block;
              position: relative;
              top: -1px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #2f4cdd;
            }
          }
        }
      }
    }
  }

  .foot-btn {
    width: 312px;
    margin-top: 60px;

    ::v-deep {
      .el-button {
        width: 100%;
        height: 50px;
      }
    }
  }

  .li {
    margin-top: 20px;

    .left {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 16px;
      font-size: 14px;

      span {
        margin-right: 6px;
        color: #4b80ff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.icon-zhifuqudaoduizhangdanguanli {
  font-size: 16px !important;
  margin-left: 2px;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-aside {
  width: 200px !important;
  background-color: rgb(253, 253, 253);
}

.user-btn {
  color: #606266;
  font-size: 14px;
  padding: 0 15px;

  span {
    display: inline-block;
    padding: 0 20px;

    &:hover {
      cursor: pointer;
      color: #5c9fe2;
    }
  }
}

.header-nav {
  background-color: rgb(70, 76, 91);
  display: flex;
  justify-content: space-between;
  color: rgb(215, 215, 215);
  height: 62px !important;
  line-height: 62px;

  .right-menus {
    font-size: 14px;
    display: flex;

    .right-menus-ul {
      display: flex;
      justify-content: flex-end;

      li {
        padding: 0 20px;
        transition: .3s;

        .tu {
          height: 40px;
          margin-right: 5px;
          border-radius: 100%;
        }

        .index-selects {
          /deep/ .el-input__inner {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: .3s;
            width: 150px;
            border: none;
            background: rgb(70, 76, 91) !important;
            color: rgb(215, 215, 215);
          }

          /deep/ .el-input__icon {
            height: auto;
            color: rgb(215, 215, 215);
          }
        }

        &:hover {
          color: #fff;
          cursor: pointer;
          background-color: #4A90E2;

          .index-selects {
            /deep/ .el-input__inner {
              color: #fff;
              cursor: pointer;
              background-color: #4A90E2 !important;
            }

            /deep/ .el-input__icon {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.avatar-nitice {
  position: relative;
  padding: 0 30px;
  line-height: 60px;
  cursor: pointer;

  i {
    font-size: 24px;
    line-height: 60px;
  }

  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #de383f;
    font-size: 10px;
    color: #fff;
    transform: scale(0.8);
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 22px;
  }
}

.store-name {
  //padding: 0 20px;
  padding-left: 20px;

  i {
    display: inline-block;
    padding: 0 20px;
  }

  &:hover {
    color: #fff;
    cursor: pointer;
    background-color: #4A90E2;
  }
}

.tree_css {
  font-family: "PingFang SC ", "PingFang SC", sans-serif;
}

// 一级菜单
.tree_css > .el-tree-node > .el-tree-node__content .font_color {
  font-weight: 650;
}

// 二级菜单
.tree_css > .el-tree-node > .el-tree-node__children .font_color {
}

// 三级菜单
.tree_css > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children .font_color {
  font-size: 15px;
  color: #666565;
}

//.custom-tree-node
</style>
