<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="less">

html,
body,
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-textarea__inner{
	background-color: #F8FAFB !important;
}
// .el-input--suffix .el-input__inner{
// 	background: #F7FAFC;
//     // height: 43px;
// 	// border:1px solid #EBEBEB !important;
// }
// .el-input__inner{
// 	background-color: #F7FAFC !important;
// }
.el-date-editor .el-range-input{
	// height: 43px !important;
	background: #F7FAFC !important;
}
.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
	// height: 43px !important;
}
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
	color:#4B80FF !important;
}
.el-input__suffix-inner{
	font-size: 18px;
}

	/* 暂无数据 */
	.nodata {
		width: 200px;
		height: 200px;
	}

	.overHide {
		overflow: hidden;
	}
</style>
