import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
  },
  mutations: {
    SET_TOKEN:(state, data)=>{
      state.token = data;
      localStorage.setItem('token',data)
    },
  },
  actions: {
    logout({ commit }) {
      
    }
  },
  modules: {
  }
})
