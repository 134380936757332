/**
 * article模块接口列表
 */
import base from './api' // 导入接口域名列表
import axios from './axios' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块

const article = {
    // post提交 登录
    login(params) {
        return axios.post(`${base.url}/authorize/login`, qs.parse(params))
    },
    // 获取菜单列表
    GetmenuList(params) {
        return axios.get(`${base.url}/index/menu/list?parentId=` + params.id)
    },
    // 获取菜单按钮权限
    GetmenuButton(params) {
        return axios.get(`${base.url}/index/menu/button?menuId=` + params.id)
    },
    // get提交  退出登录
    logout() {
        return axios.get(`${base.url}/authorize/logout`)
    },
    // 获取当前登录的用户信息
    getloginInfo() {
        return axios.get(`${base.url}/authorize/login/info`)
        // return axios.post(`${base.url}/app/user/get`)
    },
    // 修改用户头像
    updAvatar(params) {
        return axios.post(`${base.url}/app/user/updateHead`, qs.parse(params))
    },
    // 修改登录用户密码
    updPwd(params) {
        return axios.post(`${base.url}/user/upd/pwd`, qs.parse(params))
    },
    //菜单列表
    menuList(params) {
        return axios.post(`${base.url}/menu/list`, qs.parse(params))
    },
    // 添加菜单
    addMenu(params) {
        return axios.post(`${base.url}/menu/option`, qs.parse(params))
    },
    // 删除菜单
    delMenu(params) {
        return axios.post(`${base.url}/menu/delete`, qs.parse(params))
    },
    // 修改菜单
    updateMenu(params) {
        return axios.post(`${base.url}/menu/update`, qs.parse(params))
    },
    // 修改部门
    updateDept(params) {
        return axios.post(`${base.url}/dept/update`, qs.parse(params))
    },
    //删除部门
    delDept(params) {
        return axios.post(`${base.url}/dept/deleted`, qs.parse(params))
    },
    // 新增部门
    delOption(params) {
        return axios.post(`${base.url}/dept/option`, qs.parse(params))
    },
    //跨境学堂 文章列表
    schList(params) {
        return axios.post(`${base.url}/sch/list`, qs.parse(params))
    },
    // 角色列表
    roleList(params) {
        return axios.post(`${base.url}/role/list`, qs.parse(params))
    },
    // 添加角色
    roleOption(params) {
        return axios.post(`${base.url}/role/option`, qs.parse(params))
    },
    // 修改角色
    roleUpdate(params) {
        return axios.post(`${base.url}/role/update`, qs.parse(params))
    },
    // 删除角色
    roleDelete(params) {
        return axios.post(`${base.url}/role/delete`, qs.parse(params))
    },
    // 角色菜单权限
    menuRole(params) {
        return axios.post(`${base.url}/menu/roles`, qs.parse(params))
    },
    // 角色关联菜单
    roleGrant(params) {
        return axios.post(`${base.url}/role/grant/menu`, qs.parse(params))
    },
    // 新增权限
    pridd(params) {
        return axios.post(`${base.url}/menu/pri/add`, qs.parse(params))
    },
    //修改权限
    upd(params) {
        return axios.post(`${base.url}/menu/pri/upd`, qs.parse(params))
    },
    // 删除权限
    del(params) {
        return axios.post(`${base.url}/menu/pri/del`, qs.parse(params))
    },
    // 部门列表
    dept() {
        return axios.get(`${base.url}/staff/list/dept`)
    },
    // 职位列表
    deptPost(params) {
        return axios.get(`${base.url}/staff/list/post?deptId=` + params.id)
    },
    // 角色关联用户
    roleGl(params) {
        return axios.post(`${base.url}/role/roles`, qs.parse(params))
    },
    // 用户关联角色
    roleGanet(params) {
        return axios.post(`${base.url}/user/grant/role`, qs.parse(params))
    },
    // 日志列表
    log(params) {
        return axios.post(`${base.url}/sys/log/list`, qs.parse(params))
    },
    // 删除岗位
    delPost(params) {
        return axios.post(`${base.url}/post/del`, qs.parse(params))
    },
    // 修改岗位
    deptUpt(params) {
        return axios.post(`${base.url}/post/upd`, qs.parse(params))
    },
    // 新增岗位
    adddept(params) {
        return axios.post(`${base.url}/post/option`, qs.parse(params))
    },

    // 岗位列表
    getPostList(params) {
        return axios.post(`${base.url}/app/post/list`, qs.parse(params))
    },
    // 添加岗位
    addPost(params) {
        return axios.post(`${base.url}/app/post/option`, qs.parse(params))
    },
    //修改岗位
    editPost(params) {
        return axios.post(`${base.url}/app/post/update`, qs.parse(params))
    },
    //删除岗位
    delPostById(params) {
        return axios.post(`${base.url}/app/post/delete`, qs.parse(params))
    },

    // 店铺列表
    getStoreList(params) {
        return axios.post(`${base.url}/app/store/list`, qs.parse(params))
    },
    // 添加店铺
    addStore(params) {
        return axios.post(`${base.url}/app/store/option`, qs.parse(params))
    },
    //修改店铺
    editStore(params) {
        return axios.post(`${base.url}/app/store/update`, qs.parse(params))
    },
    //删除店铺
    delStoreById(params) {
        return axios.post(`${base.url}/app/store/delete`, qs.parse(params))
    },

    // 获取所有店铺列表
    getStoreListAll() {
        return axios.get(`${base.url}/app/staff/list/store`)
    },
    // 获取所有岗位列表
    getPostListAll() {
        return axios.get(`${base.url}/app/staff/list/post`)
    },

    // 获取衍生品商店列表
    getStoreShop(params) {
        return axios.post(`${base.url}/common/storeShop/list`, qs.parse(params))
    },
    // 修改衍生品商店
    editStoreShop(params) {
        return axios.post(`${base.url}/common/storeShop/update`, qs.parse(params))
    },
    // 新增衍生品商店
    addStoreShop(params) {
        return axios.post(`${base.url}/common/storeShop/option`, qs.parse(params))
    },
    // 删除衍生品商店
    delStoreShop(params) {
        return axios.post(`${base.url}/common/storeShop/delete`, qs.parse(params))
    },

    // 获取货架列表
    getStoreShelfType(params) {
        return axios.post(`${base.url}/common/storeShelfType/list`, qs.parse(params))
    },
    // 修改货架
    editStoreShelfType(params) {
        return axios.post(`${base.url}/common/storeShelfType/update`, qs.parse(params))
    },
    // 新增货架
    addStoreShelfType(params) {
        return axios.post(`${base.url}/common/storeShelfType/option`, qs.parse(params))
    },
    // 删除货架
    delStoreShelfType(params) {
        return axios.post(`${base.url}/common/storeShelfType/delete`, qs.parse(params))
    },

    // 获取商品规格列表
    getSpecification(params) {
        return axios.post(`${base.url}/app/specification/list`, qs.parse(params))
    },
    // 修改商品规格
    editSpecification(params) {
        return axios.post(`${base.url}/app/specification/update`, qs.parse(params))
    },
    // 新增商品规格
    addSpecification(params) {
        return axios.post(`${base.url}/app/specification/option`, qs.parse(params))
    },
    // 删除商品规格
    delSpecification(params) {
        return axios.post(`${base.url}/app/specification/delete`, qs.parse(params))
    },

    // 获取商品分类列表
    getClassify(params) {
        return axios.post(`${base.url}/app/classify/list`, qs.parse(params))
    },
    // 修改商品分类
    editClassify(params) {
        return axios.post(`${base.url}/app/classify/update`, qs.parse(params))
    },
    // 新增商品分类
    addClassify(params) {
        return axios.post(`${base.url}/app/classify/option`, qs.parse(params))
    },
    // 删除商品分类
    delClassify(params) {
        return axios.post(`${base.url}/app/classify/delete`, qs.parse(params))
    },

    // 首望小程序start

    // 获取商品信息表
    getCommodityInfo(params) {
        return axios.post(`${base.url}/common/commodityInfo/list`, qs.parse(params))
    },
    // 修改商品信息表
    editCommodityInfo(params) {
        return axios.post(`${base.url}/common/commodityInfo/update`, qs.parse(params))
    },
    // 新增商品信息表
    addCommodityInfo(params) {
        return axios.post(`${base.url}/common/commodityInfo/option`, qs.parse(params))
    },
    // 删除商品信息表
    delCommodityInfo(params) {
        return axios.post(`${base.url}/common/commodityInfo/delete`, qs.parse(params))
    },
    // 根据ID获取商品详情
    getCommodityInfoById(params) {
        return axios.get(`${base.url}/common/commodityInfo/getById/` + params.id)
    },
    // 根据ID上下架
    statusCommodityInfoById(params) {
        return axios.get(`${base.url}/common/commodityInfo/shelves/` + params.id)
    },

    // 获取商品类型信息表
    getCommodityTypeInfo(params) {
        return axios.post(`${base.url}/common/commodityTypeInfo/list`, qs.parse(params))
    },
    // 修改商品类型信息表
    editCommodityTypeInfo(params) {
        return axios.post(`${base.url}/common/commodityTypeInfo/update`, qs.parse(params))
    },
    // 新增商品类型信息表
    addCommodityTypeInfo(params) {
        return axios.post(`${base.url}/common/commodityTypeInfo/option`, qs.parse(params))
    },
    // 删除商品类型信息表
    delCommodityTypeInfo(params) {
        return axios.post(`${base.url}/common/commodityTypeInfo/delete`, qs.parse(params))
    },

    // 获取商品配置信息表
    getConfigurationInfo(params) {
        return axios.post(`${base.url}/common/configurationInfo/list`, qs.parse(params))
    },
    // 修改商品配置信息表
    editConfigurationInfo(params) {
        return axios.post(`${base.url}/common/configurationInfo/update`, qs.parse(params))
    },
    // 新增商品配置信息表
    addConfigurationInfo(params) {
        return axios.post(`${base.url}/common/configurationInfo/option`, qs.parse(params))
    },
    // 删除商品配置信息表
    delConfigurationInfo(params) {
        return axios.post(`${base.url}/common/configurationInfo/delete`, qs.parse(params))
    },
    // 根据类型查询所有商品配置
    getAllConfigurationInfo(params) {
        return axios.post(`${base.url}/common/configurationInfo/queryByType/` + params.id)
    },

    // 获取所有商品大分类信息
    getAllCategoriesInfo(params) {
        return axios.post(`${base.url}/common/categoriesInfo/queryAll`, qs.parse(params))
    },
    // 获取商品大分类信息表
    getCategoriesInfo(params) {
        return axios.post(`${base.url}/common/categoriesInfo/list`, qs.parse(params))
    },
    // 修改商品大分类信息表
    editCategoriesInfo(params) {
        return axios.post(`${base.url}/common/categoriesInfo/update`, qs.parse(params))
    },
    // 新增商品大分类信息表
    addCategoriesInfo(params) {
        return axios.post(`${base.url}/common/categoriesInfo/option`, qs.parse(params))
    },
    // 删除商品大分类信息表
    delCategoriesInfo(params) {
        return axios.post(`${base.url}/common/categoriesInfo/delete`, qs.parse(params))
    },

    // 获取商品小分类信息表
    getSmallCategoriesInfo(params) {
        return axios.post(`${base.url}/common/smallCategoriesInfo/list`, qs.parse(params))
    },
    // 修改商品小分类信息表
    editSmallCategoriesInfo(params) {
        return axios.post(`${base.url}/common/smallCategoriesInfo/update`, qs.parse(params))
    },
    // 新增商品小分类信息表
    addSmallCategoriesInfo(params) {
        return axios.post(`${base.url}/common/smallCategoriesInfo/option`, qs.parse(params))
    },
    // 删除商品小分类信息表
    delSmallCategoriesInfo(params) {
        return axios.post(`${base.url}/common/smallCategoriesInfo/delete`, qs.parse(params))
    },
    // 根据大分类ID获取商品小分类
    getSmallById(params) {
        return axios.get(`${base.url}/common/smallCategoriesInfo/queryAll/` + params.id)
    },

    // 获取所有属性大分类信息表列表
    getAllAttributesClassInfo() {
        return axios.get(`${base.url}/common/attributesClassInfo/list`)
    },
    // 获取属性大分类信息表列表
    getAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/attributesClassInfo/list`, qs.parse(params))
    },
    // 修改属性大分类信息表列表
    editAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/attributesClassInfo/update`, qs.parse(params))
    },
    // 新增属性大分类信息表列表
    addAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/attributesClassInfo/option`, qs.parse(params))
    },
    // 删除属性大分类信息表列表
    delAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/attributesClassInfo/delete`, qs.parse(params))
    },

    // 获取属性小分类信息表列表
    getSmallAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/smallAttributesClassInfo/list`, qs.parse(params))
    },
    // 修改属性小分类信息表列表
    editSmallAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/smallAttributesClassInfo/update`, qs.parse(params))
    },
    // 新增属性小分类信息表列表
    addSmallAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/smallAttributesClassInfo/option`, qs.parse(params))
    },
    // 删除属性小分类信息表列表
    delSmallAttributesClassInfo(params) {
        return axios.post(`${base.url}/common/smallAttributesClassInfo/delete`, qs.parse(params))
    },

    // 获取意见反馈信息表
    getFeedbackInfo(params) {
        return axios.post(`${base.url}/common/feedbackInfo/list`, qs.parse(params))
    },
    // 修改意见反馈信息表
    editFeedbackInfo(params) {
        return axios.post(`${base.url}/common/feedbackInfo/update`, qs.parse(params))
    },
    // 删除意见反馈信息表
    delFeedbackInfo(params) {
        return axios.post(`${base.url}/common/feedbackInfo/delete`, qs.parse(params))
    },
    // 处理反馈
    disposeFeedbackInfo(params) {
        return axios.post(`${base.url}/common/feedbackInfo/dispose/` + params.id)
    },

    // 获取帮助中心信息表
    getHelpCenterInfo(params) {
        return axios.post(`${base.url}/common/helpCenterInfo/list`, qs.parse(params))
    },
    // 修改帮助中心信息表
    editHelpCenterInfo(params) {
        return axios.post(`${base.url}/common/helpCenterInfo/update`, qs.parse(params))
    },
    // 新增帮助中心信息表
    addHelpCenterInfo(params) {
        return axios.post(`${base.url}/common/helpCenterInfo/option`, qs.parse(params))
    },
    // 删除帮助中心信息表
    delHelpCenterInfo(params) {
        return axios.post(`${base.url}/common/helpCenterInfo/delete`, qs.parse(params))
    },

    // 获取通知消息信息表
    getNoticeInfo(params) {
        return axios.post(`${base.url}/common/noticeInfo/list`, qs.parse(params))
    },
    // 修改通知消息信息表
    editNoticeInfo(params) {
        return axios.post(`${base.url}/common/noticeInfo/update`, qs.parse(params))
    },
    // 新增通知消息信息表
    addNoticeInfo(params) {
        return axios.post(`${base.url}/common/noticeInfo/option`, qs.parse(params))
    },
    // 删除通知消息信息表
    delNoticeInfo(params) {
        return axios.post(`${base.url}/common/noticeInfo/delete`, qs.parse(params))
    },

    // 获取优惠活动通知列表
    getSpecialOffers(params) {
        return axios.post(`${base.url}/common/activity/list`, qs.parse(params))
    },
    // 修改优惠活动通知
    editSpecialOffers(params) {
        return axios.post(`${base.url}/common/activity/update`, qs.parse(params))
    },
    // 新增优惠活动通知
    addSpecialOffers(params) {
        return axios.post(`${base.url}/common/activity/option`, qs.parse(params))
    },
    // 删除优惠活动通知
    delSpecialOffers(params) {
        return axios.post(`${base.url}/common/activity/delete`, qs.parse(params))
    },

    // 获取轮播图信息表
    getBanner(params) {
        return axios.post(`${base.url}/common/banner/list`, qs.parse(params))
    },
    // 修改轮播图信息表
    editBanner(params) {
        return axios.post(`${base.url}/common/banner/update`, qs.parse(params))
    },
    // 新增轮播图信息表
    addBanner(params) {
        return axios.post(`${base.url}/common/banner/option`, qs.parse(params))
    },
    // 删除轮播图信息表
    delBanner(params) {
        return axios.post(`${base.url}/common/banner/delete`, qs.parse(params))
    },
    // 上下架轮播图
    statusBanner(params) {
        return axios.get(`${base.url}/common/banner/shelves/` + params.id)
    },
    // 获取积分商城商品列表
    getIntegral(params) {
        return axios.post(`${base.url}/common/integral/integralGoods`, qs.parse(params))
    },
    // 修改积分商城商品
    editIntegral(params) {
        return axios.post(`${base.url}/common/integral/update`, qs.parse(params))
    },
    // 新增积分商城商品
    addIntegral(params) {
        return axios.post(`${base.url}/common/integral/addIntegral`, qs.parse(params))
    },
    // 删除积分商城商品
    delIntegral(params) {
        return axios.post(`${base.url}/common/integral/delete`, qs.parse(params))
    },
    // 获取优惠券列表
    getCoupon(params) {
        return axios.post(`${base.url}/common/couponInfo/list`, qs.parse(params))
    },
    // 添加优惠券
    addCoupon(params) {
        return axios.post(`${base.url}/common/couponInfo/option`, qs.parse(params))
    },
    // 修改优惠券
    editCoupon(params) {
        return axios.post(`${base.url}/common/couponInfo/update`, qs.parse(params))
    },
    // 删除优惠券
    delCoupon(params) {
        return axios.post(`${base.url}/common/couponInfo/delete/`, qs.parse(params))
    },
    // 优惠券上下架
    shelvesCoupon(params) {
        return axios.get(`${base.url}/common/couponInfo/shelves/` + params.id)
    },

    // 获取订单信息表列表
    getOrderInfo(params) {
        return axios.post(`${base.url}/common/orderInfo/list`, qs.parse(params))
    },
    // 获取根据ID获取订单信息
    getOrderInfoById(params) {
        return axios.post(`${base.url}/common/orderInfo/getByOrderId/` + params.id)
    },
    // 修改订单信息表
    editOrderInfo(params) {
        return axios.post(`${base.url}/common/orderInfo/update`, qs.parse(params))
    },
    // 删除订单信息表
    delOrderInfo(params) {
        return axios.post(`${base.url}/common/orderInfo/delete/`, qs.parse(params))
    },
    // 订单数量
    getOrderCount(params) {
        return axios.post(`${base.url}/common/orderInfo/getOrderCount`, qs.parse(params))
    },
    // 批量发货
    batchShipment(params) {
        return axios.post(`${base.url}/common/orderInfo/batchShipment`, qs.parse(params))
    },

    // 获取订单设置
    getOrderSet(params) {
        return axios.post(`${base.url}/common/order/set/list`, qs.parse(params))
    },
    // 修改订单设置
    editOrderSet(params) {
        return axios.post(`${base.url}/common/order/set/update`, qs.parse(params))
    },

    // 获取签到奖励信息列表
    getRewardAllocation(params) {
        return axios.post(`${base.url}/common/rewardAllocation/list`, qs.parse(params))
    },
    // 添加签到奖励信息
    addRewardAllocation(params) {
        return axios.post(`${base.url}/common/rewardAllocation/option`, qs.parse(params))
    },
    // 修改签到奖励信息
    editRewardAllocation(params) {
        return axios.post(`${base.url}/common/rewardAllocation/update`, qs.parse(params))
    },
    // 分页获取小程序用户信息
    getUserList(params) {
        return axios.post(`${base.url}/common/userInfo/queryPage`, qs.parse(params))
    },
    // 添加字典
    addDictionary(params) {
        return axios.post(`${base.url}/dictionary/add`, qs.parse(params))
    },
    // 添加字典
    delDictionary(params) {
        return axios.post(`${base.url}/dictionary/del`, qs.parse(params))
    },
    // 添加字典
    getDictionary(params) {
        return axios.post(`${base.url}/dictionary/list`, qs.parse(params))
    },
    // 修改字典
    editDictionary(params) {
        return axios.post(`${base.url}/dictionary/update`, qs.parse(params))
    },
    // 添加促销信息表
    addSalesPromotion(params) {
        return axios.post(`${base.url}/common/salesPromotion/option`, qs.parse(params))
    },
    // 添加促销信息表
    delSalesPromotion(params) {
        return axios.post(`${base.url}/common/salesPromotion/delete`, qs.parse(params))
    },
    // 添加促销信息表
    getSalesPromotion(params) {
        return axios.post(`${base.url}/common/salesPromotion/list`, qs.parse(params))
    },
    // 修改促销信息表
    editSalesPromotion(params) {
        return axios.post(`${base.url}/common/salesPromotion/update`, qs.parse(params))
    },
    // 获取所有上架促销活动
    getSalesPromotionList() {
        return axios.get(`${base.url}/common/salesPromotion/queryAll`)
    },
    // 上下架
    shelvesSalesPromotion(params) {
        return axios.get(`${base.url}/common/salesPromotion/shelves/` + params.id)
    },
    // 获取后台账号
    getStaff(params) {
        return axios.post(`${base.url}/staff/list`, qs.parse(params))
    },
    // 添加后台账号
    addStaff(params) {
        return axios.post(`${base.url}/staff/option`, qs.parse(params))
    },
    // 编辑后台账号
    editStaff(params) {
        return axios.post(`${base.url}/staff/upd`, qs.parse(params))
    },
    // 修改后台账号密码
    updStaffPwd(params) {
        return axios.post(`${base.url}/staff/upd/pwd`, qs.parse(params))
    },
    // 删除后台账号
    delStaff(params) {
        return axios.post(`${base.url}/staff/del`, qs.parse(params))
    },
    // 获取评价信息表列表
    getEvaluation(params) {
        return axios.post(`${base.url}/common/evaluationInfo/list`, params)
    },
    // 删除评价信息列表
    delEvaluation(params) {
        return axios.post(`${base.url}/common/staff/delete`, qs.parse(params))
    },

    // 获取取消原因列表
    getCancel(params) {
        return axios.post(`${base.url}/common/cancel/list`, qs.parse(params))
    },
    // 添加取消原因
    addCancel(params) {
        return axios.post(`${base.url}/common/cancel/option`, qs.parse(params))
    },
    // 删除取消原因
    delCancel(params) {
        return axios.post(`${base.url}/common/cancel/delete`, qs.parse(params))
    },
    // 修改取消原因
    editCancel(params) {
        return axios.post(`${base.url}/common/cancel/update`, qs.parse(params))
    },
    // 获取退货列表
    getOrderRefund(params) {
        return axios.post(`${base.url}/common/order/refund/list`, qs.parse(params))
    },
    // 批量退货-退货管理（批量退款）
    batchRefund(params) {
        return axios.post(`${base.url}/common/order/refund/batchRefund`, qs.parse(params))
    },
    // 批量收货-退货管理
    batchTakeRefund(params) {
        return axios.post(`${base.url}/common/order/refund/batchTake`, qs.parse(params))
    },
    // 退款订单数量
    refundOrderCount(params) {
        return axios.post(`${base.url}/common/order/refund/getOrderCount`, qs.parse(params))
    },
    // 同意退货
    agreeRefund(params) {
        return axios.post(`${base.url}/common/order/refund/agree`, qs.parse(params))
    },
    // 获取换货列表
    getOrderExchange(params) {
        return axios.post(`${base.url}/common/order/exchange/list`, qs.parse(params))
    },
    // 批量换货
    batchRefundExchange(params) {
        return axios.post(`${base.url}/common/order/exchange/batchRefund`, qs.parse(params))
    },
    // 批量收货-换货
    batchDeliverExchange(params) {
        return axios.post(`${base.url}/common/order/exchange/batchTake`, qs.parse(params))
    },
    batchTakeExchange(params) {
        return axios.post(`${base.url}/common/order/exchange/batchExchange`, qs.parse(params))
    },
    // 换款订单数量
    exchangeOrderCount(params) {
        return axios.post(`${base.url}/common/order/exchange/getOrderCount`, qs.parse(params))
    },
    // 同意换货
    agreeExchange(params) {
        return axios.post(`${base.url}/common/order/exchange/agree`, qs.parse(params))
    },
    // 拒绝退换货
    refuseAfter(params) {
        return axios.post(`${base.url}/common/order/refund/refuse/`+ params.id + '/'+params.refuseReason)
    },
    //获取地址管理列表
    getAddress(params) {
        return axios.post(`${base.url}/common/address/address/list`, qs.parse(params))
    },
    // 添加地址管理
    addAddress(params) {
        return axios.post(`${base.url}/common/address/option`, qs.parse(params))
    },
    // 删除地址管理
    delAddress(params) {
        return axios.post(`${base.url}/common/address/delete`, qs.parse(params))
    },
    // 修改地址管理
    editAddress(params) {
        return axios.post(`${base.url}/common/address/update`, qs.parse(params))
    },
    //获取运费模板列表
    getTemp(params) {
        return axios.post(`${base.url}/common/temp/temp/list`, qs.parse(params))
    },
    // 添加运费模板
    addTemp(params) {
        return axios.post(`${base.url}/common/temp/option`, qs.parse(params))
    },
    // 删除运费模板
    delTemp(params) {
        return axios.post(`${base.url}/common/temp/delete`, qs.parse(params))
    },
    // 修改运费模板
    editTemp(params) {
        return axios.post(`${base.url}/common/temp/update`, qs.parse(params))
    },
    // 运费模板详情
    getTempDetails(params) {
        return axios.post(`${base.url}/common/temp/temp/detail/`+params.id)
    },
    //获取秒杀列表
    getSeckill(params) {
        return axios.post(`${base.url}/common/sk/list`, qs.parse(params))
    },
    // 添加秒杀
    addSeckill(params) {
        return axios.post(`${base.url}/common/sk/option`, qs.parse(params))
    },
    // 删除秒杀
    delSeckill(params) {
        return axios.post(`${base.url}/common/sk/delete`, qs.parse(params))
    },
    // 修改秒杀
    editSeckill(params) {
        return axios.post(`${base.url}/common/sk/update`, qs.parse(params))
    },
    // 今日订单信息
    getTodayOrderInfo(params) {
        return axios.post(`${base.url}/common/data/getTodayOrderInfo`, qs.parse(params))
    },
    // 销售金额统计
    getSaleData(params) {
        return axios.post(`${base.url}/common/data/getSaleData`, qs.parse(params))
    },
    // 商品销量统计
    getGoodsSales(params) {
        return axios.post(`${base.url}/common/data/list`, qs.parse(params))
    },
    // 订单数据查询
    getOrderData(params) {
        return axios.post(`${base.url}/common/data/orderData`, qs.parse(params))
    },
    // 订单数据查询-表单
    getOrderDataByDay(params) {
        return axios.post(`${base.url}/common/data/orderDataByDay`, qs.parse(params))
    },
    // 销售金额统计-表单
    getSaleDataByFrom(params) {
        return axios.post(`${base.url}/common/data/getSaleDataByFrom`, qs.parse(params))
    },
    // 导出数据查询-看板
    exportOrderData(params) {
        return axios.post(`${base.url}/common/data/exportOrderData`, qs.parse(params))
    },
    // 导出销售金额-看板
    exportSalesData(params) {
        return axios.post(`${base.url}/common/data/exportSalesData`, qs.parse(params))
    },
    exportCommoditySale() {
        return axios.get(`${base.url}/common/data/exportCommoditySale`)
    },
    // 导出数据查询-表单
    exportOrderFrom(params) {
        return axios.post(`${base.url}/common/data/exportOrderFrom`, qs.parse(params))
    },
    // 导出销售金额-表单
    exportSalesFrom(params) {
        return axios.post(`${base.url}/common/data/exportSalesFrom`, qs.parse(params))
    },
    // 首望小程序end
}
export default article
