const Reg = {
    // 手机号
    phone: /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/,
    // 邮箱
    email: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
    // 价格
    price: /^\d+.?\d{0,2}$/,
    // 税号
    ein: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
}   

export default Reg