import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../Layout";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test2.vue"),
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/login/index.vue"),
    hidden: true,
  },
  {
    path: "/home",
    component: Layout,
    meta: {
      title: "首页",
      icon: "el-icon-edit",
    },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        name: "Home",
        meta: {
          title: "首页",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/userInfo",
        component: () => import("@/views/login/userInfo.vue"),
        meta: {
          title: "账户设置",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/news",
        component: () => import("@/views/login/news.vue"),
        meta: {
          title: "账户设置",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/staff",
        component: () => import("@/views/framework/staff.vue"),
        meta: {
          title: "员工管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/account",
        component: () => import("@/views/framework/account/account.vue"),
        meta: {
          title: "账号管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/dept",
        component: () => import("@/views/framework/department.vue"),
        hidden: false,
        meta: {
          title: "部门管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/menu",
        component: () => import("@/views/framework/menu.vue"),
        meta: {
          title: "菜单管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/userManger",
        component: () => import("@/views/framework/userManger.vue"),
        meta: {
          title: "角色管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/logs",
        component: () => import("@/views/framework/log.vue"),
        meta: {
          title: "日志列表",
          icon: "el-icon-edit",
        },
      },

      {
        path: "/staff/index",
        hidden: true,
        component: () => import("@/views/framework/staff.vue"),
      },
      {
        path: "/transfer",
        hidden: true,
        component: () => import("@/views/framework/transfer.vue"),
      },
      {
        path: "/department/addMent",
        hidden: true,
        component: () => import("@/views/framework/addMent.vue"),
      },
      {
        path: "/department/editment",
        hidden: true,
        component: () => import("@/views/framework/editment.vue"),
      },
      {
        path: "/department/editDept",
        hidden: true,
        component: () => import("@/views/framework/editDept.vue"),
      },
      {
        path: "/ceshi",
        hidden: true,
        component: () => import("@/ceshi.vue"),
        meta: {
          title: "测试",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/commodityInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/commodityInfo/commodityInfo.vue"),
        meta: {
          title: "商品信息表",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/addCommodityInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/commodityInfo/add/index.vue"),
        meta: {
          title: "新增商品",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/commodityInfoDetails",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/commodityInfo/details.vue"),
        meta: {
          title: "商品信息表",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/commodityTypeInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/commodityTypeInfo/commodityTypeInfo.vue"),
        meta: {
          title: "商品类型",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/integralGoods",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/integralGoods/integralGoods.vue"),
        meta: {
          title: "积分商城",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/addIntegralGoods",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/integralGoods/add/index.vue"),
        meta: {
          title: "新增积分商城商品",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/integralGoodsDetails",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/integralGoods/details.vue"),
        meta: {
          title: "积分商城商品详情",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/coupon",
        hidden: true,
        component: () => import("@/views/shouwang/promotion/coupon/coupon.vue"),
        meta: {
          title: "优惠券管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/configurationInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/configurationInfo/configurationInfo.vue"),
        meta: {
          title: "商品配置",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/categoriesInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/categoriesInfo/categoriesInfo.vue"),
        meta: {
          title: "商品大分类",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/smallCategoriesInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/smallCategoriesInfo/smallCategoriesInfo.vue"),
        meta: {
          title: "商品小分类",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/attributesClassInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/attributesClassInfo/attributesClassInfo.vue"),
        meta: {
          title: "属性大分类",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/smallAttributesClassInfo",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/smallAttributesClassInfo/smallAttributesClassInfo.vue"),
        meta: {
          title: "属性小分类",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/helpCenterInfo",
        hidden: true,
        component: () => import("@/views/shouwang/otherSettings/helpCenterInfo/helpCenterInfo.vue"),
        meta: {
          title: "帮助中心",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/noticeInfo",
        hidden: true,
        component: () => import("@/views/shouwang/service/noticeInfo/noticeInfo.vue"),
        meta: {
          title: "通知消息",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/specialOffers",
        hidden: true,
        component: () => import("@/views/shouwang/service/specialOffers/specialOffers.vue"),
        meta: {
          title: "优惠活动",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/userList",
        hidden: true,
        component: () => import("@/views/shouwang/user/userInfo/userInfo.vue"),
        meta: {
          title: "优惠活动",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderList",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderInfo/orderList/orderInfo.vue"),
        meta: {
          title: "订单信息",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderDetails",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderInfo/orderList/details.vue"),
        meta: {
          title: "订单详情",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/batchShipment",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderInfo/orderList/batchShipment.vue"),
        meta: {
          title: "批量发货",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderSet",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderInfo/orderSet/orderSet.vue"),
        meta: {
          title: "批量发货",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderCancel",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderInfo/cancel/cancel.vue"),
        meta: {
          title: "取消原因",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderRefundCancel",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderRefund//cancel.vue"),
        meta: {
          title: "退换货原因",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderRefund",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderRefund/orderRefund.vue"),
        meta: {
          title: "退货管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderRefundDetails",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderRefund/details.vue"),
        meta: {
          title: "退货详情",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderExchange",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderExchange/orderExchange.vue"),
        meta: {
          title: "换货列表",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/orderExchangeDetails",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderExchange/details.vue"),
        meta: {
          title: "换货详情",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/batchExchangeShipment",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderExchange/batchShipment.vue"),
        meta: {
          title: "批量发货-换货管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/batchExchange",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderExchange/batchExchange.vue"),
        meta: {
          title: "批量收货-换货管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/batchTake",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderRefund/batchTake.vue"),
        meta: {
          title: "批量收货-退货管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/batchRefund",
        hidden: true,
        component: () => import("@/views/shouwang/order/orderRefund/batchRefund.vue"),
        meta: {
          title: "批量收货-退货管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/feedbackInfo",
        hidden: true,
        component: () => import("@/views/shouwang/otherSettings/feedbackInfo/feedbackInfo.vue"),
        meta: {
          title: "意见反馈",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/banner",
        hidden: true,
        component: () => import("@/views/shouwang/otherSettings/banner/banner.vue"),
        meta: {
          title: "首页轮播图",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/rewardAllocation",
        hidden: true,
        component: () => import("@/views/shouwang/integral/rewardAllocation/rewardAllocation.vue"),
        meta: {
          title: "首页轮播图",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/serviceInfo",
        hidden: true,
        component: () => import("@/views/shouwang/service/serviceInfo/serviceInfo.vue"),
        meta: {
          title: "客服信息",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/evaluation",
        hidden: true,
        component: () => import("@/views/shouwang/evaluation/evaluation.vue"),
        meta: {
          title: "评论管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/salesPromotion",
        hidden: true,
        component: () => import("@/views/shouwang/promotion/salesPromotion/salesPromotion.vue"),
        meta: {
          title: "促销信息",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/address",
        hidden: true,
        component: () => import("@/views/shouwang/logistics/address/address.vue"),
        meta: {
          title: "地址管理",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/freightTemp",
        hidden: true,
        component: () => import("@/views/shouwang/logistics/freightTemp/freightTemp.vue"),
        meta: {
          title: "运费模板",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/addFreightTemp",
        hidden: true,
        component: () => import("@/views/shouwang/logistics/freightTemp/add.vue"),
        meta: {
          title: "运费模板",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/seckillGoods",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/seckillGoods/seckillGoods.vue"),
        meta: {
          title: "秒杀活动",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/addSeckillGoods",
        hidden: true,
        component: () => import("@/views/shouwang/commodity/seckillGoods/add.vue"),
        meta: {
          title: "添加秒杀活动",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/statisticsBoard",
        hidden: true,
        component: () => import("@/views/shouwang/statistics/board/board.vue"),
        meta: {
          title: "数据统计-看板",
          icon: "el-icon-edit",
        },
      },
      {
        path: "/statisticsForm",
        hidden: true,
        component: () => import("@/views/shouwang/statistics/form/form.vue"),
        meta: {
          title: "数据统计-表单",
          icon: "el-icon-edit",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && to.path !== "/") {
    localStorage.clear();
    next("/");
    alert('无权限')
  } else {
    next();
  }
});

export default router;
